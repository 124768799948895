<template>
  <div class="content">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: "ServicesManagement",
  data() {
    return {
    };
  },
  components: {
  },
};
</script>

<style scoped></style>
